<template>
  <g>
     <svg:style>
      .cls-10, .cls-11, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#inox-gradient-5);
      }

      .cls-8 {
        fill: url(#inox-gradient-6);
      }

      .cls-9 {
        fill: url(#inox-gradient-7);
      }

      .cls-10 {
        fill: url(#inox-gradient-8);
      }

      .cls-11 {
        fill: url(#inox-gradient-9);
      }
      .handle { fill: url(#handle-gradient); }
    </svg:style>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth + 75.56"
                    :y1="doorTopHeight + 185.03"
                    :x2="doorLeftWidth + 106.44"
                    :y2="doorTopHeight + 185.03"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 75.26"
                    :y1="doorTopHeight + 162.07"
                    :x2="doorLeftWidth + 106.14"
                    :y2="doorTopHeight + 162.07"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 75.56"
                    :y1="doorTopHeight + 230.98"
                    :x2="doorLeftWidth + 106.44"
                    :y2="doorTopHeight + 230.98"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 75.26"
                    :y1="doorTopHeight + 208.01"
                    :x2="doorLeftWidth + 106.14"
                    :y2="doorTopHeight + 208.01"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-5"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 75.56"
                    :y1="doorTopHeight + 93.19"
                    :x2="doorLeftWidth + 106.44"
                    :y2="doorTopHeight + 93.19"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-6"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 75.26"
                    :y1="doorTopHeight + 70.23"
                    :x2="doorLeftWidth + 106.14"
                    :y2="doorTopHeight + 70.23"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-7"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 75.56"
                    :y1="doorTopHeight + 139.14"
                    :x2="doorLeftWidth + 106.44"
                    :y2="doorTopHeight + 139.14"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-8"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 75.26"
                    :y1="doorTopHeight + 116.18"
                    :x2="doorLeftWidth + 106.14"
                    :y2="doorTopHeight + 116.18"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-9"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 11.84"
                    :y1="doorTopHeight + 150.83"
                    :x2="doorLeftWidth + 17.52"
                    :y2="doorTopHeight + 150.83"
                    xlink:href="#inox-gradient"/>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <g id="L16">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-3"
                :points="`${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 184.78} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 184.93} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 185.12} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 185.27} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 202.78} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 202.82} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 202.72} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 202.54} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 167.51} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 167.33} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 167.23} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 167.28} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 184.78}`"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 161.82} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 144.31} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 144.28} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 144.37} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 144.56} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 179.58} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 179.76} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 179.86} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 179.82} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 162.31} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 162.16} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 161.97} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 161.82}`"/>
      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 230.73} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 230.88} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 231.07} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 231.22} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 248.74} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 248.77} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 248.67} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 248.49} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 213.46} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 213.28} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 213.18} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 213.22} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 230.73}`"/>
      <polyline id="inox-4"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 207.77} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 190.26} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 190.22} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 190.32} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 190.5} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 225.53} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 225.7} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 225.8} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 225.77} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 208.26} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 208.11} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 207.92} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 207.77}`"/>
      <polyline id="inox-5"
                v-if="showInox"
                data-name="inox"
                class="cls-7"
                :points="`${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 92.94} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 93.09} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 93.29} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 93.44} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 110.95} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 110.98} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 110.89} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 110.7} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 75.68} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 75.5} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 75.4} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 75.43} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 92.94}`"/>
      <polyline id="inox-6"
                v-if="showInox"
                data-name="inox"
                class="cls-8"
                :points="`${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 69.98} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 52.47} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 52.44} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 52.54} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 52.72} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 87.75} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 87.92} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 88.02} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 87.98} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 70.48} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 70.33} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 70.14} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 69.98}`"/>
      <polyline id="inox-7"
                v-if="showInox"
                data-name="inox"
                class="cls-9"
                :points="`${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 138.89} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 139.04} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 139.24} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 139.39} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 156.89} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 156.93} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 156.84} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 156.65} ${doorLeftWidth1 + 106.44} ${doorTopHeight1 + 121.63} ${doorLeftWidth1 + 106.37} ${doorTopHeight1 + 121.44} ${doorLeftWidth1 + 106.21} ${doorTopHeight1 + 121.35} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 121.38} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 138.89}`"/>
      <polyline id="inox-8"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 115.93} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 98.42} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 98.39} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 98.49} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 98.67} ${doorLeftWidth1 + 75.26} ${doorTopHeight1 + 133.68} ${doorLeftWidth1 + 75.33} ${doorTopHeight1 + 133.87} ${doorLeftWidth1 + 75.5} ${doorTopHeight1 + 133.96} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 133.93} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 116.43} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 116.28} ${doorLeftWidth1 + 106.14} ${doorTopHeight1 + 116.08} ${doorLeftWidth1 + 106.01} ${doorTopHeight1 + 115.93}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 76
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    },
  },
}
</script>
